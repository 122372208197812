const unsupportedBrowserUrl = '/unsupported-browser.html'

function isDynamicImportSupported () {
    // Feature detection for dynamic import
    // https://caniuse.com/es6-module-dynamic-import
    try {
        new Function('return import("data:text/javascript;base64,Cg==").then(() => true)')()
        return true
    } catch (err) {
        return false
    }
}

function isFormDataGetSupported () {
    // Feature detection for FormData.get
    // https://caniuse.com/mdn-api_formdata_get
    const testFormDataType = new FormData()
    return typeof testFormDataType.get === 'function'
}

function isAbortControllerSupported () {
    // Feature detection for AbortController
    // https://caniuse.com/abortcontroller
    return typeof AbortController === 'function'
}

if (!isDynamicImportSupported() || !isFormDataGetSupported() || !isAbortControllerSupported()) {
    window.location.href = unsupportedBrowserUrl
}
